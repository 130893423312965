<template>
	<div class="view">
		<div class="main">
			<!-- TODO 하단 검은색 레이어 추가 -->
			<b-tabs justified v-model="tabIndex">
				<b-tab>
					<!-- 독도 -->
					<template v-slot:title>
						<img src="@/assets/logo-09.png" alt="영릉시간탐험대" />
					</template>
					<b-jumbotron fluid container-fluid class="even">
						<template v-slot:header
							>세종대왕의 기록을 찾아라! <br />
							'영릉 시간 탐험대'</template
						>
						<template v-slot:lead>
							역사상 가장 위대한 왕, 세종대왕이 잠든 여주 영릉이 메타버스로 거듭났습니다.
							<br class="desktop-only" />'영릉 시간 탐험대'는 집현전의 학자가 되어 메타버스 속 영릉을 누비며 <br class="desktop-only" />세종대왕의 기록을 수집하는 프로그램으로 <br class="desktop-only" />국어, 사회, 역사 등 다양한 교과를 게임과 메타버스를 통해 체험할 수 있습니다.
						</template>
						<iframe width="600" height="330" src="https://player.vimeo.com/video/711092367?h=d314fcc0fb" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</b-jumbotron>
					<b-jumbotron fluid container-fluid class="odd">
						<template v-slot:header>영릉 시간 탐험대 수업의 특장점</template>
						<template v-slot:lead>영릉 시간 탐험대로 수업하면 이런 장점이 있습니다. </template>
						<!-- 데스크탑 뷰 (세로 카드) -->
						<b-card-group deck class="transparent">
							<b-card :img-src="require('@/assets/landing-page/icon1.png')" title="">게임으로 쉽게, 재미있게 접근하는 <br class="desktop-only" /><span class="bold">디지털 역사문화 콘텐츠</span></b-card>
							<b-card :img-src="require('@/assets/landing-page/icon2.png')" title="">학생이 주인공(player)이 되어 몰입도를 높여주는 <span class="bold">체험형 교육프로그램</span></b-card>
							<b-card :img-src="require('@/assets/landing-page/icon3.png')" title="">교실수업 및 원격수업이 모두 가능한 <br class="desktop-only" /><span class="bold">블렌디드 교육방식</span></b-card>
						</b-card-group>
						<!-- 모바일 뷰 (가로 카드) -->
						<b-card-group columns class="transparent">
							<b-card no-body>
								<b-row no-gutters align-v="center">
									<b-col cols="4" xs="4">
										<b-card-img :src="require('@/assets/landing-page/icon1.png')"></b-card-img>
									</b-col>
									<b-col cols="8" xs="8">
										<b-card-body>
											<b-card-text> 게임으로 쉽게, 재미있게 접근하는 <span class="bold">디지털 역사문화 콘텐츠</span> </b-card-text>
										</b-card-body>
									</b-col>
								</b-row>
							</b-card>
							<b-card no-body>
								<b-row no-gutters align-v="center">
									<b-col cols="4" xs="4">
										<b-card-img :src="require('@/assets/landing-page/icon2.png')"></b-card-img>
									</b-col>
									<b-col cols="8" xs="8">
										<b-card-body>
											<b-card-text> 학생이 주인공(player)이 되어 몰입해서 활동하게 하는 <span class="bold">체험형 교육프로그램</span> </b-card-text>
										</b-card-body>
									</b-col>
								</b-row>
							</b-card>
							<b-card no-body>
								<b-row no-gutters align-v="center">
									<b-col cols="4" xs="4">
										<b-card-img :src="require('@/assets/landing-page/icon3.png')"></b-card-img>
									</b-col>
									<b-col cols="8" xs="8">
										<b-card-body>
											<b-card-text> 교실수업 및 원격수업이 모두 가능한 <span class="bold">O2O 교육방식</span> </b-card-text>
										</b-card-body>
									</b-col>
								</b-row>
							</b-card>
						</b-card-group>
					</b-jumbotron>

					<b-jumbotron fluid container-fluid class="even wide thirds">
						<template v-slot:header>영릉 시간 탐험대 수업 후기</template>
						<template v-slot:lead>선생님들이 영릉 시간 탐험대 수업을 하는 이유</template>
						<b-card-group deck>
							<b-card title="">
								<b-card-text> 교실에만 앉아있는 것을 힘들어하는 학생도 메타버스 공간에 들어가니 너무나 즐겁게 수업에 몰입하기 시작했어요. 단순히 정보를 발견하는 것 뿐만이 아니라 퀴즈를 풀며 한번 더 복습하니 더욱 재미있게 배움이 일어나는 것 같았습니다!</b-card-text>
								<template v-slot:footer><span class="bold">대전 **초등학교</span><br />김00 선생님</template>
							</b-card>
							<b-card title="">
								<b-card-text> 대부분의 학생들이 지루함을 느끼는 역사시간에 영릉 시간탐험대 수업을 활용하니 학생들이 오히려 이 시간을 기다리더라구요! 수업에 열정이 없던 학생도 최고 관직인 영전사를 달기 위해 열심히 메타버스 공간을 돌아다니는 모습이 아주 인상적이었습니다.</b-card-text>
								<template v-slot:footer><span class="bold">충남 **초등학교</span><br />박00 선생님</template>
							</b-card>
							<b-card title="">
								<b-card-text> 견학을 자주 하기 힘든 학교에게 안성맞춤인 콘텐츠 인 것 같아요. 영릉의 모습을 구현한 공간을 돌아다니는 것 만으로도 즐거워했지만 중간중간 실제 공간의 사진이 들어있어 더욱 현장감이 느껴졌던 것 같아요.</b-card-text>
								<template v-slot:footer><span class="bold">경북 **초등학교</span><br />이00 선생님</template>
							</b-card>
						</b-card-group>
					</b-jumbotron>
				</b-tab>
			</b-tabs>
			<Footer></Footer>
		</div>
	</div>
</template>

<script>
// 로그인 페이지
import Footer from '@/components/Footer';

// import AlertToast from "@/components/GenericAlertToast"

export default {
	name: 'Landing',
	data() {
		return {
			tabIndex: 0,
			login: false,
		};
	},
	mounted() {
		if (this.$store.state.token.length) {
			this.login = true;
			// console.log(this.login, this.$store.state.token);
		}
	},
	beforeDestroy: function() {
		// 핸들러 제거하기
		clearInterval(this.slogalInterval);
	},
	computed: {
		slogan() {
			return '‘NOLGONG SCHOOL’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다.';
			// '공간 리터러시 수업 ‘건축놀이사무소’로 학교공간을 혁신하고<br>디지털 통일 수업 ‘월페커즈’로 미래교육 문화를 만들어 나갑니다.'
		},
	},
	methods: {
		swapSlogan: function() {
			[...document.getElementsByClassName('carousel-slogan')].forEach((e) => {
				e.classList.toggle('hidden');
			});
		},
		hideWorkshopSchedule: function() {
			document.querySelector('.workshop-schedule').style.display = 'none';
		},
	},
	components: {
		Footer,
		// AlertToast
	},
	metaInfo: {
		// 페이지 제목 설정
		title: 'NOLGONG SCHOOL',
		titleTemplate: null,
		meta: [
			// SEO 설정
			{
				name: 'description',
				content:
					'학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소> | ‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'description',
			},
			{ name: 'keywords', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
			{ name: 'author', content: '(주)놀공' },
			{ name: 'subject', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
			// SNS 설정
			{
				property: 'og:type',
				content: 'website',
				vmid: 'og:type',
			},
			{
				property: 'og:title',
				content: 'NOLGONG SCHOOL',
				vmid: 'og:title',
			},
			{
				property: 'og:description',
				content: '‘NOLGONG SCHOOL’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'og:description',
			},
			{
				property: 'og:url',
				content: 'https://miraeclass.com/',
				vmid: 'og:url',
			},
			{
				property: 'og:image',
				content: 'https://miraeclass.com/static/social_image_horizontal.png',
				vmid: 'og:image',
			},
			{
				property: 'og:image:alt',
				content: '"NOLGONG SCHOOL" 문구, 건물과 깃발을 표현한 초록색 학교 모양 아이콘',
				vmid: 'og:image:alt',
			},
			{
				property: 'og:image:width',
				content: '800',
				vmid: 'og:image:width',
			},
			{
				property: 'og:image:height',
				content: '400',
				vmid: 'og:image:height',
			},
			{
				property: 'og:locale',
				content: 'ko_KR',
				vmid: 'og:locale',
			},
			{
				property: 'og:site_name',
				content: 'NOLGONG SCHOOL',
				vmid: 'og:site_name',
			},
			{
				property: 'twitter:card',
				content: 'summary',
				vmid: 'twitter:card',
			},
			{
				property: 'twitter:site',
				content: '@nolgong',
				vmid: 'twitter:site',
			},
			{
				property: 'twitter:title',
				content: 'NOLGONG SCHOOL',
				vmid: 'twitter:title',
			},
			{
				property: 'twitter:description',
				content: '‘NOLGONG SCHOOL’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'twitter:description',
			},
			{
				property: 'twitter:image',
				content: 'https://miraeclass.com/static/social_image_horizontal.png',
				vmid: 'twitter:image',
			},
			{
				property: 'twitter:image:alt',
				content: '"NOLGONG SCHOOL" 문구, 건물과 깃발을 표현한 초록색 학교 모양 아이콘',
				vmid: 'twitter:image:alt',
			},
		],
	},
};
</script>

<style scoped>
.mobile-only {
	display: none;
}
.view {
	/*min-width: 540px;*/
}
.main {
	padding-top: calc(1rem + 40px);
	padding-left: 0;
	padding-bottom: 0;
	padding-right: 0;
	word-break: keep-all;
}
h1 {
	margin-bottom: 36px;
	font-weight: 800;
	font-size: 54px;
}
.lead {
	margin-bottom: 48px;
}
a {
	color: #474747;
}
/* tabs */
.main >>> .nav-tabs img {
	width: 100%;
	max-width: 240px;
}
.main >>> .tabs .nav-tabs {
	padding-top: 2rem;
	background-color: #e3ffde;
	border-bottom: none;
	justify-content: center;
}
.main >>> .tabs .nav-item {
	max-width: 342px;
	background-color: #e3ffde;
}
.main >>> .tabs .nav-link {
	padding-top: 1.5em;
	height: 100%;
	background-color: #/ wallpeckers-assets/;
	border-top-left-radius: 2em;
	border-top-right-radius: 2em;
}
.main >>> .tabs .nav-item:first-of-type {
	z-index: 3;
}
.main >>> .tabs .nav-item:nth-of-type(2) {
	z-index: 2;
}
.main >>> .tabs .nav-item:last-of-type {
	z-index: 1;
}
.main >>> .nav-tabs .nav-link:focus,
.main >>> .nav-tabs .nav-link:hover {
	background: rgba(255, 255, 255, 0.5);
	border-bottom: none;
}
.main >>> .tabs .nav-link.active {
	background-color: #fff;
	border-color: #f6f6f6;
	border-bottom: none;
	box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.1);
}
.jumbotron-carousel >>> .carousel-inner {
	max-height: 800px;
}

.carousel-slogan-wrap {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.6) 100%);
	display: flex;
	justify-content: center;
	align-items: flex-end;
}
.carousel-slogan {
	padding-bottom: 6rem;
	font-weight: bold;
	font-size: 1.75rem;
	color: white;
	text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
	transition: opacity 1s ease-in-out;
	opacity: 1;
}
.carousel-slogan.hidden {
	opacity: 0;
	position: fixed;
}
.carousel >>> .carousel-indicators li {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	border: none;
	margin: 1.5rem 0.5rem;
	opacity: 0.3;
	transition: all 0.25s;
}
.carousel >>> .carousel-indicators li.active {
	transform: scale(1.2);
	opacity: 1;
}

.jumbotron {
	margin-bottom: 0;
}
.jumbotron .container-fluid {
	max-width: 1024px;
}
.jumbotron.wide .container-fluid {
	max-width: 100%;
}
.jumbotron.odd {
	background: #e3ffde;
	min-height: 640px;
}
.jumbotron.even {
	background: #fff;
	min-height: 640px;
}
.jumbotron-carousel {
	/*width: 100%;*/
	padding: 0;
}
.jumbotron-carousel .container-fluid {
	max-width: 100%;
	padding: 0;
}
.jumbotron.splash {
	display: flex;
	align-items: center;
	min-height: 400px !important;
}
.jumbotron.splash .lead {
	font-weight: 600;
	font-size: 1.5em;
	margin-bottom: 120px;
	white-space: pre-wrap;
}
.jumbotron.kakaotalk {
	background: #ffd700;
}
.jumbotron.kakaotalk .lead {
	font-size: 36px;
	font-weight: bold;
	margin-bottom: auto;
}
.kakao-logo {
	max-height: 50px;
	margin-left: 16px;
}
.card-title {
	font-weight: bold;
}
.card {
	word-break: break-all;
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.card,
.card-footer {
	border-radius: 10px;
}
.card-footer {
	background-color: white;
	border-top: none;
}
.jumbotron.wide .card-title {
	margin-top: 0.5em;
}
.jumbotron.wide .card-text::before,
.jumbotron.wide .card-text::after {
	/*font-size: 125%;*/
	font-style: oblique;
}
.jumbotron.wide .card-text::before {
	content: '“';
}
.jumbotron.wide .card-text::after {
	content: '”';
}
.transparent .card {
	background: transparent;
	border: none;
	box-shadow: none;
}
.card-columns.transparent {
	display: none;
}
.card-text {
	text-align: justify;
	padding-top: 1em;
	padding-bottom: 1em;
}
.card-img {
	padding: 1em;
}
.call-to-action {
	margin-top: 1em;
	font-size: 2em;
	padding: 0.25rem 0.75rem 0.5rem;
}
.call-to-action a {
	vertical-align: text-bottom;
	color: #f8f8f8;
}

.workshop-schedule {
	transition: all 1.5s;
	opacity: 0;
	z-index: 10;
	position: fixed;
	top: 80px;
	right: -200px;
	text-align: right;
	font-size: 1.5em;
}
.workshop-schedule.visible {
	opacity: 1;
	right: 1rem;
}
.workshop-schedule img {
	max-height: 144px;
	max-width: 144px;
}
.icon-close {
	opacity: 0;
	transition: opacity 3s cubic-bezier(1, -0.15, 0.5, 1);
	color: gray;
	line-height: 1em;
	margin-right: -6px;
	margin-bottom: -6px;
}
.workshop-schedule.visible .icon-close {
	opacity: 1;
}
.icon-close:hover {
	cursor: pointer;
}

/* popup */

.pop-up {
	position: absolute;
	z-index: 10;
	top: 82px;
	right: 54px;
	width: 300px;
}
.pop-up.one {
	left: 54px;
}
.pop-up.two {
	right: 54px;
}

/* .pop-up.one > div {
	margin: 0;
	padding: 0;
} */

.popup-btn-wrap .popup-btn:first-child {
	border: 0;
	border-right: 0.5px solid #ccc;
}
.popup-btn-wrap .popup-btn {
	width: 50%;
	height: 46px;
	font-size: 14px;
	color: #000;
	border: 0;
	background-color: #f2f2f2;
}

/* 화면 사이즈 별로 캐러셀 이미지 위치 조정 */
/* 가로 1920 이상 기준 */
.jumbotron-carousel >>> .carousel-inner .img-fluid {
	transform: translateY(calc((1920px - 100vw) * 0.25));
}
@media (max-width: 1920px) {
	.jumbotron-carousel >>> .carousel-inner .img-fluid {
		transform: none;
	}
}

@media (max-width: 1440px) {
	.jumbotron-carousel >>> .carousel-inner {
		max-height: 620px;
	}
}
@media (max-width: 1200px) {
	h1 {
		font-size: 44px;
	}
}
@media (max-width: 991px) {
	.desktop-only {
		display: none;
	}
	.main {
		padding-top: 48px;
	}
	h1 {
		font-size: 42px;
	}
	.carousel-slogan {
		font-size: 1.5rem;
		padding-bottom: 5rem;
	}
	.carousel >>> .carousel-indicators li {
		width: 8px;
		height: 8px;
		border-radius: 8px;
		margin: 1.25rem 0.5rem;
	}

	.lead {
		font-size: 1.2rem;
	}
	.jumbotron.splash .lead {
		font-size: 1.3em;
	}
	.jumbotron.wide.halves .card {
		min-width: 40%;
		margin-bottom: 1rem;
	}
	.jumbotron.wide .card-deck {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}
@media (max-width: 768px) {
	.mobile-only {
		display: block !important;
	}
	.logo {
		max-width: 80vw;
	}
	h1 {
		font-size: 36px;
	}
	iframe {
		max-width: 90vw;
		max-height: 50vw;
		width: 560px;
		height: 312px;
	}
	.jumbotron-carousel >>> .carousel-inner {
		min-height: 480px;
	}
	.jumbotron-carousel >>> .carousel-inner .img-fluid {
		height: 480px;
		max-width: 768px !important;
		width: auto !important;
		transform: translateX(calc((100vw - 768px) / 2));
	}
	.carousel-slogan {
		font-size: 1.2rem;
		padding-bottom: 4rem;
	}
	.jumbotron.wide .card-deck {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
	.jumbotron.wide.thirds .card {
		min-width: 80%;
		margin-bottom: 1rem;
	}
	.card-deck .card {
		margin-right: 10px;
		margin-left: 10px;
	}
	.jumbotron.kakaotalk .lead {
		font-size: 24px;
	}
	.kakao-logo {
		height: 32px;
		margin-left: 8px;
	}
	.jumbotron.odd,
	.jumbotron.even {
		min-height: 560px;
	}
	.pop-up.one,
	.pop-up.two {
		left: 50%;
		right: unset;
		transform: translateX(-50%);
	}
	.pop-up.two {
		top: 490px;
	}
	.pop-up.login + .pop-up.two {
		top: 704px;
	}
}
@media (max-width: 667px) {
	h1 {
		font-size: 30px;
	}
}
@media (max-width: 576px) {
	.main {
		padding-top: calc(1rem + 30px);
	}
	h1 {
		font-size: 28px;
	}
	.card-deck .card {
		flex: 1 0 0%;
		margin-right: 5px;
		margin-bottom: 0;
		margin-left: 5px;
	}
	.card-deck {
		display: flex;
		flex-flow: row wrap;
		margin-right: 0px;
		margin-left: 0px;
	}
	.card-deck.transparent {
		display: none;
	}
	.card-columns.transparent {
		display: block;
	}
	.card-img {
		padding: 0;
	}
	iframe {
		width: 500px;
		height: 278px;
	}
	.jumbotron.odd,
	.jumbotron.even {
		min-height: 480px;
	}
	.jumbotron.wide .card-body {
		padding-top: 0;
		padding-bottom: 0;
	}
	.jumbotron.kakaotalk .lead {
		font-size: 20px;
	}
	.main >>> .tabs .nav-link {
		padding-top: 2vw;
		border-top-left-radius: 1em;
		border-top-right-radius: 1em;
	}
}
@media (max-width: 500px) {
	.jumbotron.wide.halves .card {
		min-width: 80%;
	}
}
@media (max-width: 400px) {
	.main >>> .transparent .card-body {
		padding-top: 0;
		padding-bottom: 0;
	}
}
</style>
