<template>
	<div class="view">
		<div class="main">
			<b-row>
				<b-col class="view-title">
					<p>자주 묻는 질문 (FAQ)</p>
				</b-col>
			</b-row>

			<b-container role="tablist">
				<b-row class="container-title">
					<b-col class="text-left">
						<img class="container-title-img" src="@/assets/logo-02.png" alt="월페커즈인스쿨" />
					</b-col>
				</b-row>

				<b-row class="header">
					<b-col v-b-toggle.wallpeckers-faq-1>
						Q. 월페커즈는 무엇인가요?
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="wallpeckers-faq-1" accordion="wallpeckers-accordion" visible>
					<b-row>
						<b-col
							>A. &lt;월페커즈 - DMZ에서 베를린장벽까지&gt;는 경계라는 주제를 게임을 통해 경험할 수 있게 합니다. 2019년 베를린장벽 붕괴 30주년을 맞아 개발된 &lt;월페커즈&gt;는 게임적인 요소를 통해 한국과 독일의 분단 역사에 있어 전체적인 유사점은 물론 차이점까지 모두 아우르고자 하며
							특히 젊은 세대의 인식을 강화시키고자 합니다.</b-col
						>
					</b-row>
				</b-collapse>

				<b-row class="header">
					<b-col v-b-toggle.wallpeckers-faq-2>
						Q. 어떤 활동을 하게 되나요?
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="wallpeckers-faq-2" accordion="wallpeckers-accordion">
					<b-row>
						<b-col
							>A. 게임이 진행되는 동안 플레이어는 분단 전문 기자가 되어 게임화된 취재와 편집 과정을 통해 DMZ와 베를린장벽에 관한 소식을 담은 신문을 작성하게 됩니다. 플레이어는 정치, 스포츠, 문화 분야 등에서 흥미로운 주제를 찾고, 실제 공간에 있는 설치 작품에서 텍스트 조각을 모으며
							퍼즐을 맞추듯 기사를 맞춰 나갑니다.</b-col
						>
					</b-row>
				</b-collapse>
				<b-row class="header">
					<b-col v-b-toggle.wallpeckers-faq-3>
						Q. 월페커즈 과목이 3가지인데, 차이점이 무엇인가요?
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="wallpeckers-faq-3" accordion="wallpeckers-accordion">
					<b-row>
						<b-col
							>A. 수업상황이나 키트보유 여부에 따라 월페커즈 인스쿨, 월페커즈 앳홈, 월페커즈 메타버스 중 한 과목을 선택하여 활용하실 수 있습니다. <br />
							<ul>
								<li>교실에서 통일교육을 할 때, 월페커즈 키트가 있다면? 월페커즈 인스쿨</li>
								<li>교실에서 통일교육을 할 때, 월페커즈 키트가 없다면? 월페커즈 앳홈 또는 월페커즈 메타버스</li>
								<li>온라인수업(원격, 재택)에서 통일교육을 할 때는? 월페커즈 앳홈 또는 월페커즈 메타버스</li>
							</ul>
							월페커즈와 월페커즈 앳홈 활용법은 수업자료실 영상으로 학습하세요. <br />월페커즈와 월페커즈 앳홈을 이해하셨다면, 월페커즈 메타버스로도 수업하실수 있습니다.
						</b-col>
					</b-row>
				</b-collapse>
				<b-row class="header">
					<b-col v-b-toggle.wallpeckers-faq-4>
						Q. 월페커즈 게임에 참여하려면 어떤 기기를 준비해야 하나요?
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="wallpeckers-faq-4" accordion="wallpeckers-accordion">
					<b-row>
						<b-col
							>A. 월페커즈 과목에 따라 추천하는 기기를 안내해 드립니다.
							<ul>
								<li>월페커즈 인스쿨: 스마트폰, 태블릿</li>
								<li>월페커즈 앳홈: PC(노트북), 스마트폰, 태블릿</li>
								<li>월페커즈 메타버스: PC(노트북)</li>
							</ul>
						</b-col>
					</b-row>
				</b-collapse>

				<b-row class="header">
					<b-col v-b-toggle.wallpeckers-faq-5>
						Q. 교실에서 사용하고 싶어요!
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="wallpeckers-faq-5" accordion="wallpeckers-accordion">
					<b-row>
						<b-col>
							A. 교실에서 &lt;월페커즈&gt; 게임을 하기 위해 준비해야 할 사항은 다음과 같습니다.<br />
							&nbsp;<br />
							선생님은<br />
							<ul>
								<li>월페커즈 수업키트</li>
								<li>게임 프레스코드 및 수업교안 (놀공스쿨 교사자료실 활용)</li>
								<li>학생들이 움직이며 활동할 수 있는 교실 공간</li>
								<li>인터넷 접속 가능한 Wifi나 모바일 데이터를 사용할 수 있는 네트워크 환경</li>
							</ul>
							학생은<br />
							<ul>
								<li>스마트폰, 태블렛 등 개인 모바일 기기 혹은 학교 공용 스마트기기</li>
							</ul>
							<br />
							※ 월페커즈 게임은 별도의 웹사이트에서 접속 가능합니다.<br />
							기존의 월페커즈 게임앱은 더 이상 사용할 수 없습니다.
						</b-col>
					</b-row>
				</b-collapse>

				<b-row class="header">
					<b-col v-b-toggle.wallpeckers-faq-6>
						Q. 키트를 어떻게 받을 수 있나요?
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="wallpeckers-faq-6" accordion="wallpeckers-accordion">
					<b-row>
						<b-col>
							A. 월페커즈 수업키트 무상지원은 2022년 2월말로 종료되었습니다. 수업키트가 품절되어, 지원해 드리기 어려운 점 양해 부탁드립니다. <br />
							&nbsp;<br />
							하지만 수업키트가 없어도 월페커즈 수업을 진행할 수 있습니다. 월페커즈 메타버스 및 월페커즈 앳홈 버전에는 수업키트 콘텐츠가 탑재되어 있습니다. 월페커즈 메타버스 또는 월페커즈 앳홈 버전을 활용해 주시기 바랍니다.
							<ul>
								<li>전화: 070-4190-4420</li>
								<li>이메일: cs@nolgong.com</li>
								<li>카카오채널: <a href="https://pf.kakao.com/_sFfTxb">놀공(nolgong)</a></li>
							</ul>
						</b-col>
					</b-row>
				</b-collapse>
				<b-row class="header">
					<b-col v-b-toggle.wallpeckers-faq-7>
						Q. 수업키트가 없는데 어떻게 하죠?
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="wallpeckers-faq-7" accordion="wallpeckers-accordion">
					<b-row>
						<b-col>A. 수업키트가 없어도 월페커즈 수업을 진행할 수 있습니다. 월페커즈 메타버스 및 월페커즈 앳홈 버전에는 수업키트 콘텐츠가 탑재되어 있습니다. 월페커즈 메타버스 또는 월페커즈 앳홈 버전을 활용해 주시기 바랍니다. </b-col>
					</b-row>
				</b-collapse>
				<b-row class="header">
					<b-col v-b-toggle.wallpeckers-faq-8>
						Q. 온라인수업(원격수업, 재택수업)이 가능한가요?
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="wallpeckers-faq-8" accordion="wallpeckers-accordion">
					<b-row>
						<b-col>A. 네. 온라인수업(원격수업 또는 재택수업)에서 월페커즈를 활용하실 수 있습니다. <br />월페커즈 메타버스 및 월페커즈 앳홈 버전에는 수업키트 콘텐츠가 탑재되어 있습니다. <br />온라인수업에서는 월페커즈 메타버스 또는 월페커즈 앳홈 버전을 활용해 주시기 바랍니다. </b-col>
					</b-row>
				</b-collapse>
				<b-row class="header">
					<b-col v-b-toggle.wallpeckers-faq-9>
						Q. 월페커즈 in 메타버스는 어떤 환경에서 수업 가능한가요?
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="wallpeckers-faq-9" accordion="wallpeckers-accordion">
					<b-row>
						<b-col>A. 공용 Wi-fi나 모바일 데이터를 사용할 수 있는 네트워크 환경에서 데스크탑 컴퓨터(또는 노트북)에서 구글 크롬브라우저를 열어 월페커즈 in 메타버스 페이지에 접속하면 됩니다. </b-col>
					</b-row>
				</b-collapse>

				<!-- <b-row class="header">
          <b-col v-b-toggle.wallpeckers-faq-6>
            Q. 키트 받을 수 있는 기간은 언제인가요?
            <span class="icon icon-arrow-down when-closed float-right" />
            <span class="icon icon-arrow-up when-opened float-right" />
          </b-col>
        </b-row>
        <b-collapse id="wallpeckers-faq-6" accordion="wallpeckers-accordion">
          <b-row>
            <b-col>A. 10월 23일부터 시작되어 제한된 키트 소진시까지입니다.</b-col>
          </b-row>
        </b-collapse> -->

				<!-- <b-row class="header">
          <b-col v-b-toggle.wallpeckers-faq-7>
            Q. 키트를 받을 수 있는 대상은 누구인가요?
            <span class="icon icon-arrow-down when-closed float-right" />
            <span class="icon icon-arrow-up when-opened float-right" />
          </b-col>
        </b-row>
        <b-collapse id="wallpeckers-faq-7" accordion="wallpeckers-accordion">
          <b-row>
            <b-col>A. 엠티처 회원가입 하신 전국의 초/중/고등학교 선생님입니다.</b-col>
          </b-row>
        </b-collapse> -->

				<b-row class="header">
					<b-col v-b-toggle.wallpeckers-faq-10>
						Q. 수업을 할 수 있는 대상은 누구인가요?
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="wallpeckers-faq-10" accordion="wallpeckers-accordion">
					<b-row>
						<b-col>A. &lt;월페커즈&gt;는 글의 문맥을 이해하며 빠진 단서를 찾아가야 하는 활동이므로 초등학교 저학년 학생들은 다소 어렵게 느낄 수 있습니다. 초등학교 고학년부터 수업을 진행하실 것을 권장합니다.</b-col>
					</b-row>
				</b-collapse>

				<b-row v-b-toggle.wallpeckers-faq-11 class="header last-header">
					<b-col>
						Q. 키트를 받았는데 어떻게 수업에 활용하면 좋을지 모르겠어요.
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="wallpeckers-faq-11" accordion="wallpeckers-accordion">
					<b-row>
						<b-col>
							A. 놀공스쿨 자료실에서 월페커즈 수업활용법을 소개한 영상을 시청하실 수 있습니다.<br />
							월페커즈는 비대면 온라인 수업에서도 활용할 수 있도록 월페커즈 앳홈 서비스를 제공하고<br />
							있습니다. 또한 사전/본/사후 수업교안을 제공하고 있으니 많은 활용 부탁드립니다.<br />
							&nbsp;<br />
							월페커즈 수업활용법 영상
							<ul>
								<li>1편: 월페커즈 게임 방법</li>
								<li>2편: 월페커즈 앳홈(원격수업용) 게임 방법</li>
								<li>3편: 월페커즈 게임 셋팅 및 수업자료 안내</li>
							</ul>
							<br />
							※ 자료실은 놀공스쿨에 가입한 월페커즈 선생님께 제공됩니다.
						</b-col>
					</b-row>
				</b-collapse>
			</b-container>

			<b-container role="tablist">
				<b-row class="container-title">
					<b-col class="text-left">
						<img class="container-title-img" src="@/assets/logo-01.png" alt="건축놀이사무소" />
					</b-col>
				</b-row>
				<b-row class="header">
					<b-col v-b-toggle.gunnolsa-faq-1>
						Q. 건축놀이사무소는 무엇인가요?
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="gunnolsa-faq-1" accordion="gunnolsa-accordion">
					<b-row>
						<b-col
							>A. &lt;건축놀이사무소&gt;는 미래학교를 향한 학교공간혁신의 과정에 학생이 직접 참여할 수 있도록 공간감수성을 학습하는 프로그램입니다. 모둠을 이뤄 건축놀이사무소를 설립하고 동화 속 주인공을 위한 집을 새로 지어주는 과정을 통해, 공간을 관찰하고 측정하며, 공간을 조합해 실제
							세상에 펼쳐보게 됩니다. 기본으로 제공되는 키트에 포함된 25장의 건축놀이 카드로 다양한 공간활동을 연계해 진행하실 수 있습니다.</b-col
						>
					</b-row>
				</b-collapse>

				<b-row class="header">
					<b-col v-b-toggle.gunnolsa-faq-2>
						Q. 어떤 활동을 하게 되나요?
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="gunnolsa-faq-2" accordion="gunnolsa-accordion">
					<b-row>
						<b-col
							>공간감수성의 학습 및 숙달을 위해 건축놀이사무소에서는 공간에 대한 지식과 놀이의 규칙을 연결해 새로운 놀이를 발명하는 활동을 진행합니다. 먼저 학생들은 동화 속 주인공에게 새로운 집을 지어주기 위해 거장 건축가의 아이디어를 살펴보고, 공간의 요소를 배치해 나갑니다. 이어서
							공간 속 놀이의 규칙을 연결해 하나의 놀이를 완성합니다. 결과로 지어진 건축놀이를 학교 안 공간에 두어, 함께 나눌 수 있도록 지도해주세요.</b-col
						>
					</b-row>
				</b-collapse>

				<b-row class="header">
					<b-col v-b-toggle.gunnolsa-faq-3>
						Q. 교실에서 사용하고 싶어요!
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="gunnolsa-faq-3" accordion="gunnolsa-accordion">
					<b-row>
						<b-col>
							A. &lt;건축놀이사무소&gt; 수업을 하기 위해서는 수업키트가 있어야 합니다. 놀공스쿨<br />
							자료실에서는 수업교안, 활동자료를 추가로 다운받으실 수 있습니다.
						</b-col>
					</b-row>
				</b-collapse>

				<b-row class="header">
					<b-col v-b-toggle.gunnolsa-faq-4>
						Q. 어떤 환경에서 수업이 가능한가요?
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="gunnolsa-faq-4" accordion="gunnolsa-accordion">
					<b-row>
						<b-col>A. 학생들이 움직이며 활동할 수 있는 충분한 공간이 필요합니다. 건축놀이사무소는 별도의 디지털 요소가 없는 오프라인 활동입니다. 수업 자료를 출력해 활용하고, 학교 속 아이들이 직접 만든 공간과 놀이를 배치할 곳을 준비해주시면 좋습니다.</b-col>
					</b-row>
				</b-collapse>

				<b-row class="header">
					<b-col v-b-toggle.gunnolsa-faq-5>
						Q. 키트를 어떻게 받을 수 있나요?
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="gunnolsa-faq-5" accordion="gunnolsa-accordion">
					<b-row>
						<b-col>
							A. 건축놀이사무소 수업키트 무상지원은 2022년 2월말로 종료되었습니다. <br />수업키트가 품절되어, 지원해 드리기 어려운 점 양해 부탁드립니다. <br />
							<ul>
								<li>전화: 070-4190-4420</li>
								<li>이메일: cs@nolgong.com</li>
								<li>카카오채널: <a href="https://pf.kakao.com/_sFfTxb">놀공(nolgong)</a></li>
							</ul>
						</b-col>
					</b-row>
				</b-collapse>

				<!-- <b-row class="header">
          <b-col v-b-toggle.gunnolsa-faq-6>
            Q. 키트 받을 수 있는 기간은 언제인가요?
            <span class="icon icon-arrow-down when-closed float-right" />
            <span class="icon icon-arrow-up when-opened float-right" />
          </b-col>
        </b-row>
        <b-collapse id="gunnolsa-faq-6" accordion="gunnolsa-accordion">
          <b-row>
            <b-col>A. 10월 23일부터 시작되어 제한된 키트 소진시까지입니다.</b-col>
          </b-row>
        </b-collapse> -->

				<!-- <b-row class="header">
          <b-col v-b-toggle.gunnolsa-faq-7>
            Q. 키트를 받을 수 있는 대상은 누구인가요?
            <span class="icon icon-arrow-down when-closed float-right" />
            <span class="icon icon-arrow-up when-opened float-right" />
          </b-col>
        </b-row>
        <b-collapse id="gunnolsa-faq-7" accordion="gunnolsa-accordion">
          <b-row>
            <b-col>A. 엠티처 회원가입 하신 전국의 초등학교 선생님입니다. </b-col>
          </b-row>
        </b-collapse> -->

				<b-row class="header">
					<b-col v-b-toggle.gunnolsa-faq-8>
						Q. 수업을 할 수 있는 대상은 누구인가요?
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="gunnolsa-faq-8" accordion="gunnolsa-accordion">
					<b-row>
						<b-col>A. &lt;건축놀이사무소&gt;는 공간의 특징을 이해하며 활동과 연관짓는 활동이므로 초등학교 저학년 학생들은 다소 어렵게 느낄 수 있습니다. 비례와 측정 개념을 익히는 초등학교 3학년부터 수업을 진행하실 것을 권장합니다.</b-col>
					</b-row>
				</b-collapse>

				<b-row v-b-toggle.gunnolsa-faq-9 class="header last-header">
					<b-col>
						Q. 키트를 받았는데 어떻게 수업에 활용하면 좋을지 모르겠어요.
						<span class="icon icon-arrow-down when-closed float-right" />
						<span class="icon icon-arrow-up when-opened float-right" />
					</b-col>
				</b-row>
				<b-collapse id="gunnolsa-faq-9" accordion="gunnolsa-accordion">
					<b-row>
						<b-col>
							A. 놀공스쿨 자료실에서 건축놀이사무소 수업활용법을 소개한 영상을 시청하실 수<br />
							있습니다. 또한 수업교안을 제공하고 있으니 많은 활용 부탁드립니다.<br />
							&nbsp;<br />
							건축놀이사무소 수업활용법 영상<br />
							<ul>
								<li>1편: 공간감수성과 건축놀이사무소</li>
								<li>2편: 건축놀이사무소 수업활용 팁</li>
								<li>3편: 건축놀이사무소를 응용한 수업활동</li>
							</ul>
							<br />
							※ 자료실은 놀공스쿨에 가입한 건축놀이사무소 선생님께 제공됩니다.
						</b-col>
					</b-row>
				</b-collapse>
			</b-container>

			<b-row align-h="center">
				<b-col class="text-center"> 추가문의사항은 <span class="mail-clipboard" data-clipboard-text="cs@nolgong.com" data-info-text="클릭해서 이메일주소 복사">cs@nolgong.com</span>으로 부탁드립니다. </b-col>
			</b-row>
			<FeedbackIcon />
		</div>
		<Footer />
	</div>
</template>

<script>
// 월페커즈 안내/FAQ 페이지
import Footer from '@/components/Footer';
import FeedbackIcon from '@/components/FeedbackIcon';
import ClipboardJS from 'clipboard';

var clipboard = new ClipboardJS('.mail-clipboard');
clipboard.on('success', function(e) {
	e.trigger.setAttribute('data-info-text', '이메일주소 복사 완료!');
	setTimeout(() => e.trigger.setAttribute('data-info-text', '클릭해서 이메일주소 복사'), 5000);
	e.clearSelection();
});

export default {
	name: 'About',
	metaInfo: {
		// 페이지 제목 설정
		title: '자주 묻는 질문',
		titleTemplate: '%s | NOLGONG SCHOOL',
		meta: [
			// SEO 설정
			{ name: 'description', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>에 관해 자주 묻는 질문에 답변해드립니다.', vmid: 'description' },
			{ name: 'author', content: '(주)놀공' },
			{ name: 'subject', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
			// SNS 설정
			{
				property: 'og:type',
				content: 'website',
				vmid: 'og:type',
			},
			{
				property: 'og:title',
				content: '자주 묻는 질문',
				vmid: 'og:title',
			},
			{
				property: 'og:description',
				content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>에 관해 자주 묻는 질문에 답변해드립니다.',
				vmid: 'og:description',
			},
			{
				property: 'og:url',
				content: 'https://miraeclass.com/about',
				vmid: 'og:url',
			},
			{
				property: 'og:image',
				content: 'https://miraeclass.com/static/social_image_horizontal.png',
				vmid: 'og:image',
			},
			{
				property: 'og:image:alt',
				content: '"NOLGONG SCHOOL" 문구, 건물과 깃발을 표현한 초록색 학교 모양 아이콘',
				vmid: 'og:image:alt',
			},
			{
				property: 'og:locale',
				content: 'ko_KR',
				vmid: 'og:locale',
			},
			{
				property: 'og:site_name',
				content: 'NOLGONG SCHOOL',
				vmid: 'og:site_name',
			},
			{
				property: 'og:image:width',
				content: '800',
				vmid: 'og:image:width',
			},
			{
				property: 'og:image:height',
				content: '400',
				vmid: 'og:image:height',
			},
			{
				property: 'twitter:card',
				content: 'summary',
				vmid: 'twitter:card',
			},
			{
				property: 'twitter:site',
				content: '@nolgong',
				vmid: 'twitter:site',
			},
			{
				property: 'twitter:title',
				content: '자주 묻는 질문',
				template: (chunk) => `${chunk} | NOLGONG SCHOOL`,
				vmid: 'twitter:title',
			},
			{
				property: 'twitter:description',
				content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>에 관해 자주 묻는 질문에 답변해드립니다.',
				vmid: 'twitter:description',
			},
			{
				property: 'twitter:image',
				content: 'https://miraeclass.com/static/social_image_horizontal.png',
				vmid: 'twitter:image',
			},
			{
				property: 'twitter:image:alt',
				content: '"NOLGONG SCHOOL" 문구, 건물과 깃발을 표현한 초록색 학교 모양 아이콘',
				vmid: 'twitter:image:alt',
			},
		],
	},
	components: {
		FeedbackIcon,
		Footer,
	},
	mounted() {
		document.dispatchEvent(new Event('render-event'));
	},
};
</script>

<style scoped>
ul {
	margin-top: 1rem;
	padding-left: 20px;
}
.container .row {
	/*margin: 0;*/
}
.container-title {
	margin: 0;
	padding: 0.25em 1em;
	border-bottom: 1px solid #ebebeb;
}
.container-title-img {
	max-width: 200px;
	margin: 0 0 1em;
}
.collapsed .when-opened,
.not-collapsed .when-closed {
	display: none;
}
.container .header:first-of-type {
	padding-top: 0.25em;
}
.last-header {
	border-bottom: none;
}
.last-header.not-collapsed {
	border-bottom: 1px solid #d8d8d8;
}
.store-badge {
	height: 50px;
}
.mail-clipboard {
	color: #ff6936;
	position: relative;
}
.mail-clipboard:hover {
	cursor: pointer;
}
.mail-clipboard:after {
	content: '';
	opacity: 0;
}
.mail-clipboard:hover:after {
	opacity: 1;
	transition: opacity 0.1s ease-in 0.5s;
	width: 11.5em;
	padding: 0.1em 0.25em;
	font-size: 0.75rem;
	text-align: center;
	position: absolute;
	bottom: -1.75em;
	left: -0.25em;
	background-color: rgba(0, 188, 112, 0.95);
	border-radius: 0.5em;
	color: black;
	content: attr(data-info-text);
}
.view >>> .feedback {
	/*bottom: 110px;*/
}

@media (min-width: 992px) {
	.container {
		max-width: 720px;
	}
}

@media (max-width: 576px) {
	.store-badge {
		height: 40px;
	}
	.store-badge-col {
		padding: 5px;
	}
	.header {
		font-size: 16px;
		line-height: 20px;
	}
	.icon {
		width: 20px;
		height: 20px;
		position: absolute;
		right: 8px;
	}
}
</style>
