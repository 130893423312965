import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import jwtDecode from "jwt-decode";

/* eslint-disable */

// TODO handle 429 too many requests

Vue.use(Vuex);

// 백엔드 서버 api URI 주소
Vue.prototype.$baseURL = "https://noldev-api.miraeclass.com/";

const apiURL = Vue.prototype.$baseURL + "api";
// const apiURL = "https://noldev-api.miraeclass.com/api";
// const apiURL = "https://noldev-api.miraeclass.com/api";
// const apiURL = "http://0.0.0.0:8001/api"
//const apiURLAuth = "https://noldev-api.miraeclass.com/nolauth";
const apiURLAuth = Vue.prototype.$baseURL + "nolauth";

// 모든 http 요청에 공통으로 적용되는 설정
axios.interceptors.request.use(
	function(config) {
		// 요청 헤더에 디폴트로 Authorization 필드 설정
		const token = localStorage.getItem("accessToken");
		if (!!token && token !== "null") {
			// 토큰값 앞의 JWT는 서버에서 jwt라는 것을 인식하게 해주는 접두어. 서버 설정에서 변경할 수도 있습니다
			config.headers.Authorization = `JWT ${token}`;
		}
		return config;
	},
	function(error) {
		// 요청 자체가 실패해서 응답을 받지 못했을 경우. 기본값 그대로
		return Promise.reject(error.request);
	}
);

// 모든 http 응답에 공통으로 적용되는 행동
axios.interceptors.response.use(
	function(response) {
		// 요청 후 돌려받은 응답의 status 값을 store에 저장한다
		store.state.serverStatus = response.status;
		return response;
	},
	function(error) {
		// 서버에서 응답이 돌아왔고 에러일 경우
		// 오류 status 값을 store에 저장한다
		store.state.serverStatus = error.response.status;
		return Promise.reject(error.response);
	}
);

export const store = new Vuex.Store({
	state: {
		// state: 컴포넌트 간 공유할 data 속성
		// 서버에서 넘겨준 token 값
		token: localStorage.getItem("accessToken") || "",
		refresh: localStorage.getItem("refreshToken") || "",
		// app_popup_acknowledged: localStorage.getItem('appPopupAcknowledged') || "",
		// 실시간 서버 status 응답 코드 (API 콜 할 때마다 업데이트해줌)
		serverStatus: "",
		// 로그인한 이용자의 email
		email: "",
		// 로그인한 이용자의 이름
		full_name: "",
		is_approved: false,
		kits: [],
		navbarState: 0,
	},

	getters: {
		// 정적 state와 달리 동적으로 반환할 수 있는 속성
		appPopupAcknowledged: (state) => () => {
			const acknowledgedDateString = localStorage.getItem("appPopupAcknowledged");
			// 최근 1주일 이내에 다시 보지 않기 체크박스를 눌렀는지 여부
			// acknowledgedDateString이 비어있을 경우 Date.parse 결과가 NaN이 되고 아래 부등식은 false 반환
			return new Date() - Date.parse(acknowledgedDateString) < 7 * 24 * 60 * 60 * 1000;
		},
		popupAcknowledged: (state) => (noticeId) => {
			const acknowledgedDateString = localStorage.getItem(`${noticeId}PopupAcknowledged`);
			// 최근 1일 이내에 다시 보지 않기 버튼을 눌렀는지 여부
			// acknowledgedDateString이 비어있을 경우 Date.parse 결과가 NaN이 되고 아래 부등식은 false 반환
			return new Date() - Date.parse(acknowledgedDateString) < 24 * 60 * 60 * 1000;
		},
		// jwt가 유효한지 (시간이 남아 있는지) 클라이언트에서 확인하기 위한 유틸리티 함수
		tokenExists: (state) => () => {
			return !!state.token;
		},
		tokenExpirationTime: (state) => () => {
			try {
				// jwt의 유효시간 필드를 반환한다.
				let decoded = jwtDecode(localStorage.accessToken);
				return decoded.exp;
			} catch (e) {
				// 토큰에 오류가 있으면 null을 반환한다.
				console.error(e);
				return null;
			}
		},
		tokenRemainingTime: (state, getters) => () => {
			let exp = getters.tokenExpirationTime();
			if (exp) {
				// jwt의 exp는 초 단위 UNIX TIME이고 자바스크립트 Date.now()는 밀리초 단위이므로 후자를 1000으로 나눠줌
				return exp - Math.floor(Date.now() / 1000);
			} else {
				// 오류 발생시 토큰 무효로 간주하고 남은 시간도 없앤다
				return 0;
			}
		},
		tokenValid: (state, getters) => () => {
			return getters.tokenExists() && getters.tokenRemainingTime() > 0;
		},
		refreshExists: (state) => () => {
			return !!state.refresh;
		},
		refreshExpirationTime: (state) => () => {
			try {
				// jwt의 유효시간 필드를 반환한다.
				let decoded = jwtDecode(state.refresh);
				return decoded.exp;
			} catch (e) {
				// 토큰에 오류가 있으면 null을 반환한다.
				console.error(e);
				return null;
			}
		},
		refreshRemainingTime: (state, getters) => () => {
			let exp = getters.refreshExpirationTime();
			if (exp) {
				// jwt의 exp는 초 단위 UNIX TIME이고 자바스크립트 Date.now()는 밀리초 단위이므로 후자를 1000으로 나눠줌
				return exp - Math.floor(Date.now() / 1000);
			} else {
				// 오류 발생시 토큰 무효로 간주하고 남은 시간도 없앤다
				return 0;
			}
		},
		refreshValid: (state, getters) => () => {
			return getters.refreshExists() && getters.refreshRemainingTime() > 0;
		},
		getUserId: (state, getters) => () => {
			// jwt에 저장된 현재 유저 식별자를 반환한다
			return jwtDecode(state.token).user_id;
		},
	},

	mutations: {
		// mutations: state 값에 접근하여 변경하는 메소드
		// 첫번째 인자로 state는 디폴트 값

		UPDATE_TOKEN(state, response) {
			// 액세스 토큰을 저장한다 (이메일+비밀번호로 로그인할 때나 토큰 갱신시)
			state.token = response.data.access;
			localStorage.accessToken = state.token;
		},

		UPDATE_REFRESH_TOKEN(state, response) {
			// 리프레시 토큰을 저장한다 (이메일+비밀번호로 로그인시 발급)
			state.refresh = response.data.refresh;
			localStorage.refreshToken = state.refresh;
		},

		REMOVE_TOKENS(state) {
			// 로그아웃 단계에서 토큰 값 제거
			localStorage.removeItem("accessToken");
			localStorage.removeItem("refreshToken");
			state.token = "";
			state.refresh = "";
		},

		UPDATE_EMAIL(state, response) {
			// 유저 이메일주소를 저장해둔다
			state.email = response.data.email;
		},

		UPDATE_NAME(state, response) {
			// 유저 이름을 저장해둔다
			const result = response.data.results[0];
			state.full_name = result.full_name ? result.full_name : "";
			state.is_approved = result.is_approved ? result.is_approved : false;
			state.kits = result.kits ? result.kits : [];
		},

		ACKNOWLEDGE_POPUP(state, noticeId) {
			if (noticeId === "inschoolApp") {
				localStorage.appPopupAcknowledged = new Date();
			} else {
				const key = `${noticeId}PopupAcknowledged`;
				localStorage[key] = new Date();
			}
		},

		UPDATE_NAVBAR(state) {
			state.navbarState += 1;
		},
	},

	actions: {
		// actions: 비동기 요청을 담당하는 메소드
		// api 요청 시엔 axios 이용

		SIGNUP({ commit, dispatch }, { email, password, re_password }) {
			// 회원가입 (주: 이때 프로필도 백엔드에서 자동 생성됩니다)
			return axios
				.post(`${apiURL}/auth/users/`, {
					email,
					password,
					re_password,
				})
				.catch((error) => error);
		},

		REQUEST_WELCOME_EMAIL({ commit }, { email }) {
			return axios
				.post(`${apiURL}/welcome/`, {
					email,
				})
				.catch((error) => error);
		},
		SIGNIN({ commit }, { email, password }) {
			// 로그인 요청
			return axios
				.post(`${apiURL}/auth/jwt/create/`, { email, password })
				.then((response) => {
					commit("UPDATE_TOKEN", response);
					commit("UPDATE_REFRESH_TOKEN", response);
					commit("UPDATE_NAVBAR");
					return response;
				})
				.catch((error) => error);
		},

		SIGNOUT({ commit }) {
			// 로그아웃 요청. JWT는 서버에서 관리하지 않기 때문에 API 요청 없음
			commit("REMOVE_TOKENS");
			commit("UPDATE_NAVBAR");
		},

		REFRESH_TOKEN({ commit }, { refresh }) {
			// access 토큰 갱신. refresh token 자체는 갱신되지 않는다
			return axios
				.post(`${apiURL}/auth/jwt/refresh/`, { refresh })
				.then((response) => {
					commit("UPDATE_TOKEN", response);
					return response;
				})
				.catch((error) => error);
		},

		SEARCH_SCHOOL({ commit }, { keyword }) {
			// 프로필 정보 수정 화면에서 학교 검색 요청
			return axios.post(`${apiURL}/schools/search/`, { keyword }).catch((error) => error);
		},

		POST_SCHOOL({ commit }, { school_name, school_address, zipcode }) {
			// 학교 직접 입력
			return axios.post(`${apiURL}/schools/`, { name: school_name, address: school_address, zipcode: zipcode }).catch((error) => error);
		},

		GET_SCHOOL({ commit }) {
			// 사용자가 속한 학교 조회
			return axios.get(`${apiURL}/schools/`).catch((error) => error);
		},

		POST_SESSION({ commit }, payload) {
			// home에서 세션 신청 요청
			const p = payload;
			p["teacher"] = store.getters.getUserId();
			return axios.post(`${apiURL}/sessions/`, p).catch((error) => error);
		},

		LIST_SESSION({ commit }) {
			// 세션 목록 조회 요청
			return axios.get(`${apiURL}/sessions/`).catch((error) => error);
		},

		GET_SESSION({ commit }, { session_id }) {
			// 개별 세션 조회 요청
			return axios.get(`${apiURL}/sessions/${session_id}/`).catch((error) => error);
		},

		START_SESSION({ commit }, { session_id }) {
			// 세션 시작
			return axios.patch(`${apiURL}/sessions/${session_id}/`, { has_started: true }).catch((error) => error);
		},

		CANCEL_SESSION({ commit }, { session_id }) {
			// 세션 삭제
			return axios.patch(`${apiURL}/sessions/${session_id}/`, { deleted: true }).catch((error) => error);
		},

		END_SESSION({ commit }, { session_id }) {
			// 세션 종료
			return axios.patch(`${apiURL}/sessions/${session_id}/`, { has_ended: true }).catch((error) => error);
		},

		PRINT_SESSION({ commit }, { session_id }) {
			// 세션 PDF 모아서 인쇄
			return axios.post(`${apiURL}/sessions/${session_id}/print/`, {}).catch((error) => error);
		},

		UPDATE_SESSION_PDF({ commit }, { session_id, pdfs }) {
			return axios
				.patch(`${apiURL}/sessions/${session_id}/`, {
					pdf_urls: pdfs,
				})
				.catch((error) => error);
		},

		GET_PROFILE({ commit }) {
			// home&mypage 프로필 정보 조회 요청
			return axios
				.get(`${apiURL}/profile/`)
				.then((response) => {
					commit("UPDATE_NAME", response);
					return response;
				})
				.catch((error) => error);
		},

		PUT_PROFILE({ commit }, payload) {
			// 프로필 수정
			return axios
				.put(`${apiURL}/profile/${store.getters.getUserId()}/`, payload)
				.then((response) => {
					commit("UPDATE_NAVBAR");
					return response;
				})
				.catch((error) => error);
		},

		PATCH_PROFILE({ commit }, payload) {
			// 프로필 수정
			return axios
				.patch(`${apiURL}/profile/${store.getters.getUserId()}/`, payload)
				.then((response) => {
					commit("UPDATE_NAVBAR");
					return response;
				})
				.catch((error) => error);
		},

		DELETE_PROFILE({ commit }) {
			return axios.delete(`${apiURL}/profile/${store.getters.getUserId()}/`).catch((error) => error);
		},

		DELETE_USER({ commit }, payload) {
			// console.log(payload)
			return axios.delete(`${apiURL}/auth/users/me/`, payload).catch((error) => error);
		},

		GET_USER({ commit }) {
			// 유저 기본정보 (id, email) 조회 요청
			return axios
				.get(`${apiURL}/auth/users/me/`)
				.then((response) => {
					commit("UPDATE_EMAIL", response);
					return response;
				})
				.catch((error) => error);
		},

		CHANGE_PASSWORD({ commit }, payload) {
			return axios.post(`${apiURL}/auth/users/set_password/`, payload).catch((error) => error);
		},

		PASSWORD_RESET({ commit }, payload) {
			return axios.post(`${apiURL}/auth/users/reset_password/`, payload).catch((error) => error);
		},

		PASSWORD_RESET_CONFIRM({ commit }, { new_password, re_new_password, uid, token }) {
			return axios.post(`${apiURL}/auth/users/reset_password_confirm/`, { new_password, re_new_password, uid, token }).catch((error) => error);
		},

		GET_GAME_TYPES({ commit }) {
			return axios.get(`${apiURL}/game-types/`).catch((error) => error);
		},

		MAKE_ACKNOWLEDGE_POPUP({ commit }, noticeId) {
			commit("ACKNOWLEDGE_POPUP", noticeId);
		},


		NOLAUTH_SIGNIN_CHECK({ commit }, payload) {
			return axios.post(`${apiURLAuth}/check_auth/`, payload).catch((error) => error);
		},
		NOLAUTH_APPLIST({ commit }) {
			return axios.get(`${apiURLAuth}/app_list/`).catch((error) => error);
		},
		NOLAUTH_CONSENT({ commit }, payload) {
			return axios.post(`${apiURLAuth}/consent_to_use_app/`, payload).catch((error) => error);
		},
		
	},
});
