<template>
	<b-row class="footer">
		<b-col>
			<b-row>
				<a href="https://nolgong.com/" target="_blank"><img class="logo" src="@/assets/logo-footer.svg"/></a>
			</b-row>
			<hr />
			<b-row class="footer-text">
				<b-col class="text-left no-padding">
					<!-- <b-row class="bold">놀공스쿨</b-row> -->
					<!-- <b-row>
            <b-col class="footer-field-left bold">후원사</b-col>
            <b-col><a href="http://www.mirae-n.com/" target="_blank">(주)미래엔</a></b-col>
          </b-row> -->
					<!-- <svg xmlns="http://www.w3.org/2000/svg" width="1848" height="2" viewBox="0 0 1848 2" fill="none">
						<path d="M0 1L1848 0.999838" stroke="#474747" />
					</svg> -->
					<b-row>
						<b-col class="footer-field-left bold">놀공스쿨</b-col>
					</b-row>
					<b-row>
						<b-col class="footer-field-left bold">개발사</b-col>
						<b-col><a href="http://nolgong.com/" target="_blank">(주)놀공</a></b-col>
					</b-row>
					<b-row>
						<b-col class="footer-field-left bold">문의</b-col>
						<b-col><a href="mailto:cs@nolgong.com">cs@nolgong.com</a> | 070-4190-4420</b-col>
					</b-row>
					<b-row><br /></b-row>
					<b-row class="">
						<b-col class="">
							<a href="/static/terms_of_agreement_20200511.pdf" target="_blank">서비스 이용약관</a><span class="xs-break"></span> <a href="/static/privacy_20200511.pdf" target="_blank">개인정보처리방침</a><span class="xs-break"></span><span class="bold">©2023 놀공스쿨</span>
						</b-col>
					</b-row>
				</b-col>
				<b-col class="logo-set-wrap">
					<b-row align-h="end">
						<b-col class="no-padding text-right">
							<!-- <a href="http://www.mirae-n.com/" target="_blank"><img class="logo-set" src="@/assets/logo-05.png" alt="(주)미래엔" /></a> -->
							&emsp;
							<a href="http://nolgong.com/" target="_blank"><img class="logo-set" src="@/assets/nolgong-footer.svg" alt="(주)놀공"/></a>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-col>
	</b-row>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
	position: relative;
	width: 100%;
	padding: 1em 36px;
	border: none;
	color: #474747;
	background-color: #fefefe;
	/* 푸터를 맨 바닥으로 밀기 위한 설정 https://developer.mozilla.org/en-US/docs/Web/CSS/Layout_cookbook/Sticky_footers */
	/*flex-shrink: 0;*/
}

.footer .row {
	/* margin-left: 0;
	margin-right: 0; */
	padding-left: 0;
	padding-right: 0;
}

.footer > .col {
	max-width: 1010px;
	margin: 0 auto;
}
.no-padding {
	padding: 0;
}
.logo {
	max-width: 146px;
	max-height: 63px;
	/* padding: 1em 0; */
	object-fit: contain;
}
hr {
	margin: 0.75rem 0;
}
.footer-text,
.footer-text a {
	color: #474747;
}
.text-left {
	white-space: pre;
}
.footer-field-left {
	max-width: 3.5em;
}
.logo-set {
	max-height: 32px;
	margin-left: 32px;
	margin-bottom: 8px;
}
.col.logo-set-wrap {
	position: absolute;
	bottom: 0;
	right: 0;
	max-width: 67vw;
}
.xs-break:after {
	content: '\2003|\2003';
}
@media (max-width: 768px) {
	.footer {
		padding: 1em 2em;
	}
}

@media (max-width: 576px) {
	.footer {
		padding: 1em 1em;
		font-size: 0.8em;
	}

	.footer .row {
		margin: 0;
		padding: 0;
	}
	.logo {
		max-width: 200px;
		padding: 0;
	}
	.logo-set {
		max-height: 24px;
		margin-left: 10px;
	}
	.xs-break:after {
		content: '\a';
	}
}
@media (max-width: 400px) {
	.logo-set {
		max-height: 20px;
	}
	.footer {
		font-size: 0.75em;
	}
}
</style>
