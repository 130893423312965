<template>
	<div class="view fixed-footer">
		<div class="main">
			<b-row>
				<b-col class="view-title">
					<p>수업 관리</p>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="view-description">
					<p>수업 일정을 등록하고 지난 수업 결과를 조회할 수 있습니다.</p>
				</b-col>
			</b-row>

			<!-- 수업 목록 -->
			<b-container class="home--sessionlist">
				<b-row class="list-title">
					<b-col class="text-center">등록된 수업정보</b-col>
				</b-row>
				<b-list-group v-if="sessions.length > 0">
					<b-list-group-item class="home--listitem">
						<b-row class="itemrow-wrap">
							<b-col class="text-center">과목</b-col>
							<b-col cols="3" md="4" class="text-center">수업 제목</b-col>
							<b-col class="text-center">날짜</b-col>
							<b-col class="text-center desktop">시간</b-col>
							<b-col class="text-center desktop">게임 길이</b-col>
							<b-col class="text-center">상태</b-col>
						</b-row>
					</b-list-group-item>
					<b-list-group-item v-for="session in sessions" v-bind:key="session.id" class="home--listitem">
						<router-link v-bind:to="sessionPath(session.id)">
							<b-row :class="{ 'itemrow-wrap': true, 'is-past': isPast(session) }">
								<b-col class="text-center">{{ session.class_type ? session.class_type : '-' }}</b-col>
								<b-col cols="3" md="4" class="text-center session-title">{{ session.title ? session.title : '-' }}</b-col>
								<b-col class="text-center mobile">{{ trimDatetime(session.start_date).substring(5, 10) }}</b-col>
								<b-col class="text-center desktop">{{ trimDatetime(session.start_date) }}</b-col>
								<b-col class="text-center desktop">{{ getTime(session.start_date) }}</b-col>
								<b-col class="text-center desktop">{{ session.play_time + '분' }}</b-col>
								<b-col class="text-center">
									<b-badge pill :variant="getSessionStateVariant(session)">
										{{ getSessionStateText(session) }}
									</b-badge>
								</b-col>
							</b-row>
						</router-link>
					</b-list-group-item>
				</b-list-group>
				<div v-else class="home--emptysession">
					<b-spinner v-if="!loaded"></b-spinner>
					<p v-if="loaded">{{ $store.state.full_name }} 선생님, 환영합니다!</p>
					<p v-if="loaded">아래 버튼을 눌러 새로운 수업을 등록하세요.</p>
				</div>

				<div class="home--innerbutton-wrap">
					<b-button :pressed="false" v-b-popover.click.blur="'Content'" block href="#" v-b-modal.modal-1 class="home--innerbutton">수업 새로 등록하기</b-button>
				</div>
			</b-container>

			<b-button block href="#" v-b-modal.modal-1 class="home--button">수업 새로 등록하기</b-button>
			<ClassListCreateModal v-on:createSession="listSession"></ClassListCreateModal>
			<FeedbackIcon />
		</div>
		<Footer />
	</div>
</template>

<script>
// TODO: 지난 세션과 앞으로의 세션 구분하기 (탭?)
// TODO: 수업 숨기기 버튼?

import ClassListCreateModal from '@/components/ClassListCreateModal';
import FeedbackIcon from '@/components/FeedbackIcon';
import Footer from '@/components/Footer';

// 월페커즈 메인 랜딩 페이지
export default {
	name: 'ClassList',
	metaInfo: {
		// 페이지 제목 설정
		title: 'NOLGONG SCHOOL',
		titleTemplate: null,
		meta: [
			// SEO 설정
			{
				name: 'description',
				content:
					'학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소> | ‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'description',
			},
			{ name: 'keywords', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
			{ name: 'author', content: '(주)놀공' },
			{ name: 'subject', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
			// SNS 설정
			{
				property: 'og:type',
				content: 'website',
				vmid: 'og:type',
			},
			{
				property: 'og:title',
				content: 'NOLGONG SCHOOL',
				template: (chunk) => `${chunk}`,
				vmid: 'og:title',
			},
			{
				property: 'og:description',
				content: '‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'og:description',
			},
			{
				property: 'og:url',
				content: 'https://miraeclass.com/home',
				vmid: 'og:url',
			},
			{
				property: 'og:image',
				content: 'https://miraeclass.com/static/social_image_horizontal.png',
				vmid: 'og:image',
			},
			{
				property: 'og:image:alt',
				content: '"NOLGONG SCHOOL" 문구, 건물과 깃발을 표현한 초록색 학교 모양 아이콘',
				vmid: 'og:image:alt',
			},
			{
				property: 'og:image:width',
				content: '800',
				vmid: 'og:image:width',
			},
			{
				property: 'og:image:height',
				content: '400',
				vmid: 'og:image:height',
			},
			{
				property: 'og:locale',
				content: 'ko_KR',
				vmid: 'og:locale',
			},
			{
				property: 'og:site_name',
				content: 'NOLGONG SCHOOL',
				vmid: 'og:site_name',
			},
			{
				property: 'twitter:card',
				content: 'summary',
				vmid: 'twitter:card',
			},
			{
				property: 'twitter:site',
				content: '@nolgong',
				vmid: 'twitter:site',
			},
			{
				property: 'twitter:title',
				content: 'NOLGONG SCHOOL',
				vmid: 'twitter:title',
			},
			{
				property: 'twitter:description',
				content: '‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. NOLGONG SCHOOL에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'twitter:description',
			},
			{
				property: 'twitter:image',
				content: 'https://miraeclass.com/static/social_image_horizontal.png',
				vmid: 'twitter:image',
			},
			{
				property: 'twitter:image:alt',
				content: '"NOLGONG SCHOOL" 문구, 건물과 깃발을 표현한 초록색 학교 모양 아이콘',
				vmid: 'twitter:image:alt',
			},
		],
	},
	data() {
		return {
			// 조회한 세션 리스트
			sessions: [],
			classTypes: {
				WALLPECKERS: '월페커즈',
			},
			loaded: false,
		};
	},
	mounted() {
		this.listSession(); // 세션 목록 조회 요청
	},
	computed: {
		futureSessions() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			const todayTrimmed = this.trimDatetime(today.toISOString());
			return this.sessions.filter((s) => Date.parse(s.start_date) >= Date.parse(todayTrimmed));
		},
		pastSessions() {
			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			const todayTrimmed = this.trimDatetime(today.toISOString());
			return this.sessions.filter((s) => Date.parse(s.start_date) < Date.parse(todayTrimmed));
		},
	},
	methods: {
		sessionPath(id) {
			return '/session/' + id;
		},
		trimDatetime(str) {
			const words = str.split('T');
			return words[0];
		},
		getTime(str) {
			return str.substring(11, 16);
		},
		// 세션 목록 조회 요청
		listSession() {
			this.$store.dispatch('LIST_SESSION').then((res) => {
				if (this.$store.state.serverStatus === 200) {
					this.loaded = true;
					this.sessions = res.data.results;
					// console.log(this.sessions)
				}
				if (this.$store.state.serverStatus === 400) {
					console.log('failed to get sessions data');
				}
				// 토큰 기간 만료로 요청 실패
				if (this.$store.state.serverStatus === 401) {
					// window.location.reload()
					alert('다시 로그인하십시오.');
					this.$router.push('/login');
				}
			});
		},
		isPast(session) {
			// 현재 시간이 세션 시작 시간 이후일 경우 true 반환
			const now = new Date();
			return Date.parse(session.start_date) < Date.parse(now);
		},
		// 세션의 진행 상태 디스플레이용 정보 반환
		getSessionStateText(session) {
			if (!session.has_started) {
				if (session.is_active) {
					return '진행 가능';
				} else {
					if (this.isPast(session)) {
						return '미진행';
					} else {
						return '수업 예정';
					}
				}
			} else {
				if (!session.has_ended) {
					return '진행중';
				} else {
					return '수업 종료';
				}
			}
		},
		getSessionStateVariant(session) {
			if (!session.has_started) {
				if (session.is_active) {
					return 'warning';
				} else {
					if (this.isPast(session)) {
						return 'secondary';
					} else {
						return 'secondary';
					}
				}
			} else {
				if (!session.has_ended) {
					return 'success';
				} else {
					return 'info';
				}
			}
		},
	},
	components: {
		ClassListCreateModal, // 수업 신청 모달
		FeedbackIcon,
		Footer,
	},
};
</script>

<style scoped>
.subtitle-wrap {
	padding: 0.5rem 0;
	width: 90%;
	margin: 0 auto;
	word-break: keep-all;
}
.subtitle {
	font-size: 0.8rem;
}
.home--button {
	background-color: #ff6936;
	color: #fff;
	border: none;
	font-weight: bold;
	margin: 2rem auto;
	max-width: 720px;
}
@media (min-width: 992px) {
	.home--button {
		display: none;
	}
}

/* session list */
.home--sessionlist {
	min-height: 35vh;
	max-width: 968px;
	padding: 0;
}
.list-title {
	padding: 1em 0;
	margin: 0;
}
.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
	width: auto;
}
/*.home--sessionlist >>> .nav {
  margin-bottom: 15px;
}
.home--sessionlist >>> .nav-link {
  color: #666;
}

.home--sessionlist >>> .nav-link.active {
  background-color: #ff6936;
  color: #fff;
  font-weight: 600;
}
*/
.home--listitem:hover {
	background-color: #c5e7d5;
}
.home--sessionlist > p {
	border-bottom: lightgray 1px solid;
	padding-bottom: 1rem;
	margin-bottom: 0;
}
.home--sessionlist .itemrow-wrap p {
	color: #000;
	margin-bottom: 0;
}
.list-group {
	overflow-y: auto;
	height: 41vh;
}
.list-group-item,
.list-title {
	border: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
/*.list-group-item:first-child {
  border-top: 1px solid rgba(0,0,0,.1);
}*/

.itemrow-wrap {
	display: flex;
	justify-content: space-between;
	padding: 0.5rem 1rem;
}
.is-past {
	opacity: 0.7;
}
.itemrow-wrap p:nth-child(2) {
	color: #ff6936;
}
.itemrow-wrap .col,
.itemrow-wrap .col-md-4 {
	padding: 0;
}
.itemrow-wrap .col {
	color: #666;
}
.session-title {
	color: black;
}
.list-group-item:first-child {
	color: #666;
}
.list-group-item:first-child:hover {
	background-color: #fff;
}
.list-group-item:first-child,
.list-group-item:last-child {
	border-radius: 0;
}
.home--emptysession {
	padding: 1rem 0;
	min-height: 35vh;
	color: #333;
}
.home--emptysession > p {
	margin-bottom: 0;
}
.home--innerbutton-wrap {
	padding: 1rem 0;
}
.home--innerbutton {
	display: none;
}
.badge-success {
	background-color: #ff6936;
}
@media (min-width: 992px) {
	.home--innerbutton {
		display: inline;
		width: 30%;
		background-color: #ff6936;
		color: #fff;
		border: none;
		font-weight: bold;
		margin: 0 auto;
	}
}
@media (max-width: 991px) {
	.list-group-item {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
	.itemrow-wrap {
		margin: 0;
	}
	.session-title {
		text-align: left !important;
	}
	.session-title:before {
		content: '[';
		opacity: 0;
	}
	.session-title:after {
		content: ']';
		opacity: 0;
	}
}
@media (max-width: 576px) {
	.itemrow-wrap {
		padding: 0.5rem 0;
	}
}
@media (max-width: 360px) {
	.itemrow-wrap {
		font-size: 0.9em;
	}
}
</style>
