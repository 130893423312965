<template>
	<b-container>
		<p class="header">
			초대 링크를 복사해 학생들에게 보내주세요. <br />
			<b-button id="copy-invite" ref="inviteButton" class="orange my-2" @click="copyInvite">
				<strong>초대 링크 복사</strong>
			</b-button>
		</p>
		<b-row>
			<b-col ref="invite" class="p-2 bg-light">
				{{ teacherName }} 선생님의 <strong>{{ classTitle }}</strong
				>에 초대합니다.<br />
				<br />
				◦ 주제: {{ classTitle }}<br />
				◦ 시간: {{ dateTimeString }}<br />
				◦ 참가게임: {{ session.class_type }}<br />
				◦ 참가링크:
				<a v-if="session.class_type.includes('앳홈')" href="https://wallpeckers.miraeclass.com/athome/">https://wallpeckers.miraeclass.com/athome/</a>
				<a v-else-if="session.class_type.includes('메타버스')" href="https://miraeclass.com/wallpeckers_metaverse.html" target="_blank">https://miraeclass.com/wallpeckers_metaverse.html</a>
				<a v-else-if="session.class_type.includes('영릉')" href="https://miraeclass.com/sejong_metaverse_dev.html" target="_blank">https://miraeclass.com/sejong_metaverse_dev.html</a>
				<a v-else href="https://wallpeckers.miraeclass.com/">https://wallpeckers.miraeclass.com/</a>
				<br />
				◦ 프레스코드: {{ session.press_code }}<br />
				◦ 게임길이: {{ session.play_time }}분<br />
				◦ 사용환경:
				<span v-if="session.class_type.includes('앳홈')">데스크탑 컴퓨터(또는 노트북)에서 구글 크롬브라우저를 열어 접속</span>
				<span v-else-if="session.class_type.includes('메타버스')">데스크탑 컴퓨터(또는 노트북)에서 구글 크롬브라우저를 열어 접속</span>
				<span v-else-if="session.class_type.includes('영릉')">데스크탑 컴퓨터(또는 노트북)에서 구글 크롬브라우저를 열어 접속</span>
				<span v-else>모바일 기기 웹브라우저(구글 크롬, 사파리 등)에서 링크 접속</span>
			</b-col>
		</b-row>
		<b-row align-h="center"> </b-row>
	</b-container>
</template>

<script>
export default {
	name: 'SessionInvite',
	props: ['session'],
	computed: {
		teacherName() {
			const name = this.$store.state.full_name;
			return name;
		},
		classTitle() {
			const customTitle = this.session.title;
			if (customTitle.length) {
				return customTitle;
			} else {
				return `놀공스쿨 수업`;
			}
		},
		dateTimeString() {
			const datetime = new Date(this.session.start_date);
			return datetime.toLocaleString('ko-KR', {
				dateStyle: 'long',
				timeStyle: 'short',
			});
		},
	},
	methods: {
		copyInvite() {
			const inviteText = this.$refs.invite.innerText;
			const inviteButton = this.$refs.inviteButton;
			navigator.clipboard
				.writeText(inviteText)
				.then(() => {
					console.log('copied to clipboard');
					inviteButton.classList.add('copied');
					window.setTimeout(() => {
						inviteButton.classList.toggle('copied');
					}, 3000);
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},
};
</script>

<style scoped>
.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/*max-width: 588px;*/
}
ul {
	padding: 1rem 0 0 1rem;
}
#copy-invite.copied:after {
	content: '복사되었습니다.';
	color: gray;
	font-size: 0.8rem;
	cursor: initial;
	position: absolute;
	top: 3.75rem;
	left: calc(50% + 4.5rem);
}

@media (min-width: 992px) {
}
</style>
