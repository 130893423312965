<template>
	<div class="view fixed-footer">
		<div class="main">
			<b-row class="logo-wrap">
				<b-col class="logo">
					<img src="@/assets/miki_logo_v1.png" />
				</b-col>
			</b-row>
			<b-row class="" align-h="center">
				<b-col class="text-center form-wrap">
					<b-form-group id="signin-input">
						<img class="logo-ie" src="@/assets/miki_logo_v1.png" />
						<b-row>
							<b-col>
								<b-form-input class="input" v-model="email" v-on:keyup.enter="makeSignIn(email, password)" v-on:input="resetValidation" placeholder="이메일주소를 입력해주세요"></b-form-input>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<b-form-input class="input" v-model="password" v-on:keyup.enter="makeSignIn(email, password)" v-on:input="resetValidation" placeholder="비밀번호를 입력해주세요" type="password"></b-form-input>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<b-form-invalid-feedback id="signin-feedback" :state="signin_validation"> 이메일/비밀번호가 잘못되었습니다. </b-form-invalid-feedback>
							</b-col>
						</b-row>
					</b-form-group>

					<b-row>
						<b-col>
							<b-button class="orange" disabled v-if="!routerCheck" type="submit"> 로그인 </b-button>
							<b-button class="orange" v-if="routerCheck" type="submit" v-on:click="makeSignIn(email, password)"> 로그인 </b-button>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<router-link :to="{ name: 'NolauthSignUp', params: { app_id: this.$route.query.app_id } }">
								<b-button class="orange">회원가입</b-button>
							</router-link>
						</b-col>
					</b-row>
					<b-row class="font-menu">
						<b-col class="link" v-on:click="findPassword">비밀번호 찾기</b-col>
					</b-row>
				</b-col>
			</b-row>
		</div>
		<Footer />
	</div>
</template>

<script>
import Footer from '@/components/Footer';

// IE 하위버전 경고
if (navigator.userAgent.indexOf('MSIE') !== -1) {
	alert('이 웹사이트는 인터넷 익스플로러 10 이하에서 제대로 작동하지 않을 수 있습니다. 최신 브라우저에서 이용해주세요.');
}
export default {
	name: 'NolauthSignIn',
	metaInfo: {
		// 페이지 제목 설정
		title: '로그인',
		titleTemplate: '%s | NOLGONG SCHOOL',
		meta: [
			// SEO 설정
			{
				name: 'description',
				content:
					'학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소> | ‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'description',
			},
			{ name: 'keywords', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
			{ name: 'author', content: '(주)놀공' },
			{ name: 'subject', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
			// SNS 설정
			{
				property: 'og:type',
				content: 'website',
				vmid: 'og:type',
			},
			{
				property: 'og:title',
				content: 'NOLGONG SCHOOL',
				vmid: 'og:title',
			},
			{
				property: 'og:description',
				content: '‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'og:description',
			},
			{
				property: 'og:url',
				content: 'https://miraeclass.com/login',
				vmid: 'og:url',
			},
			{
				property: 'og:image',
				content: 'https://miraeclass.com/static/social_image_horizontal.png',
				vmid: 'og:image',
			},
			{
				property: 'og:image:alt',
				content: '"NOLGONG SCHOOL" 문구, 건물과 깃발을 표현한 초록색 학교 모양 아이콘',
				vmid: 'og:image:alt',
			},
			{
				property: 'og:image:width',
				content: '800',
				vmid: 'og:image:width',
			},
			{
				property: 'og:image:height',
				content: '400',
				vmid: 'og:image:height',
			},
			{
				property: 'og:locale',
				content: 'ko_KR',
				vmid: 'og:locale',
			},
			{
				property: 'og:site_name',
				content: 'NOLGONG SCHOOL',
				vmid: 'og:site_name',
			},
			{
				property: 'twitter:card',
				content: 'summary',
				vmid: 'twitter:card',
			},
			{
				property: 'twitter:site',
				content: '@nolgong',
				vmid: 'twitter:site',
			},
			{
				property: 'twitter:title',
				content: 'NOLGONG SCHOOL',
				vmid: 'twitter:title',
			},
			{
				property: 'twitter:description',
				content: '‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. NOLGONG SCHOOL에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'twitter:description',
			},
			{
				property: 'twitter:image',
				content: 'https://miraeclass.com/static/social_image_horizontal.png',
				vmid: 'twitter:image',
			},
			{
				property: 'twitter:image:alt',
				content: '"NOLGONG SCHOOL" 문구, 건물과 깃발을 표현한 초록색 학교 모양 아이콘',
				vmid: 'twitter:image:alt',
			},
		],
	},
	data() {
		// 로그인 시 필요한 필드들
		return {
			// 로그인 데이터
			email: '',
			password: '',
			signin_validation: true, // 서버 응답이 유효한지 판단
		};
	},
	computed: {
		// 필수 입력 처리
		email_validation() {
			return this.email.length > 1;
		},

		// 필수 입력 처리
		password_validation() {
			return this.password.length > 2;
		},

		// validation 통과하지 않으면 버튼에서 makeSignIn 메소드 동작하지 않음
		routerCheck() {
			if (this.email_validation && this.password_validation) {
				return true;
			}
			return false;
		},
	},
	methods: {
		// 로그인 요청
		makeSignIn(email, password) {
			// actions 메소드 호출
			this.$store.dispatch('SIGNIN', { email, password }).then((res) => {
				// 유효하지 않은 회원 정보
				if (this.$store.state.serverStatus === 401) {
					this.signin_validation = false;
				} else if (this.$store.state.serverStatus === 200) {
					this.signin_validation = true;
					this.$store.email = email;

					this.check_auth();

					/* 서버 응답이 정상일 경우 홈 페이지로 돌아감
					 * 중복 이동을 막기 위한 에러 처리 */
					//this.$router.push(this.$route.query.redirect || "/home").catch((ex) => ex);
				}
				return res;
			});
		},
		resetValidation() {
			this.signin_validation = true;
		},
		findPassword() {
			this.$router.push('/password/reset').catch((ex) => ex);
		},
		pushFAQ() {
			this.$router.push('/about').catch((ex) => ex);
		},

		check_auth() {
			this.$store
				.dispatch(
					'NOLAUTH_SIGNIN_CHECK',
					{ app_id: this.$route.query.app_id } // "1eb4113c-ea56-48ff-b011-2e8178824c19"}
				)
				.then((res) => {
					console.log(res);
					if (res.status == 200) {
						if (res.data.msg === 'login needed') {
							console.log('need login');
						} else if (res.data.msg === 'consent needed') {
							console.log('move to consent');
							// window.location.href = "http://localhost:8000/nolauth/consent_to_use_app?app_id=" + this.$route.query.app_id;
							// window.location.href = this.$baseURL + "nolauth/consent_to_use_app?app_id=" + this.$route.query.app_id + "&url=" + this.$route.query.url;
							this.$router.push({ name: 'ConsentToUseApp', params: { app_id: this.$route.query.app_id, url: this.$route.query.url } }).catch((ex) => ex);
						} else if (res.data.msg === 'login success') {
							// this.$router.push("/home").catch((ex) => ex);
							window.location.href = res.data.redirect_uri + '?app_id=' + res.data.app_id + '&token=' + res.data.token;
						} else if (res.data.msg === 'ok') {
							window.location.href = res.data.redirect_uri + '?app_id=' + res.data.app_id + '&token=' + res.data.token;
						}
					}
				});
		},
	},
	mounted() {
		console.log(this.$route.query);
		this.check_auth();
	},

	components: {
		Footer,
	},
};
</script>

<style scoped>
/* 로고 IE 대응 */
.logo-ie {
	display: auto;
	height: 277px;
	width: 277px;
}
.logo-wrap {
	display: none;
}
/* 모던 브라우저 */
@supports (display: none) {
	.logo {
		overflow: hidden;
		padding-top: 8vh;
		padding-bottom: 4vh;
	}
	.logo img {
		height: 277px;
		width: 277px;
	}
	.logo-ie {
		display: none;
	}
	.logo-wrap {
		display: flex;
	}
}
.form-wrap {
	max-width: 440px;
	margin-top: 1.5em;
}
.input {
	/*padding: 0.5em;*/
	margin: auto;
	margin-top: 1em;
	width: 85%;
	color: #000000;
	font-size: 0.9em;
	border-color: #000000;
	border-width: 1px;
	border-radius: 2mm;
	background-color: #ffffff;
}
.btn {
	margin-bottom: 1em;
	width: 85%;
	font-size: 1rem;
	font-weight: bold;
}
.privacy {
	color: #979797;
}
.link {
	font-size: 0.8em;
	cursor: pointer;
}
.font-menu {
	margin: 1.25em auto;
	font-size: 1em;
	color: #979797;
}
.font-menu :hover {
	text-decoration: #979797 underline;
}
.break {
	display: none;
}

/* 화면 높이 너무 짧으면 요소 간 margin 조정 */
@media (max-height: 792px) {
	.logo img {
		width: 200px;
		height: 200px;
	}
	.form-wrap {
		margin-top: 0.5em;
	}
	.input {
		height: 36px;
	}
	.btn {
		line-height: 1.5em;
	}
	.font-menu {
		margin: 0.5em auto;
	}
}
@media (max-height: 667px) {
	.btn {
		line-height: 1.25em;
	}
	.btn.green {
		margin-bottom: 0.5em;
	}
	.input {
		height: 30px;
	}
	.font-menu {
		font-size: 0.9em;
	}
}

@media (max-width: 991px) {
	.input {
		height: 36px;
	}
	.btn {
		line-height: 1.5em;
	}
}
</style>
